import React from "react"
import { graphql } from "gatsby"
import Hub from "../components/Hub.js"
import favicon from "../assets/favicon.png"
import defaultImage from "../assets/default-image.jpg"

const TagsHub = ({ location, data }) => {
  const page = data.prismicTag
  return (
    <Hub 
      page={page} 
      slides={data.allPrismicCreation.nodes} 
      tags={data.allPrismicTag.nodes} 
      location={location} 
      hub={`tags/${page.uid}`} 
    />
  )
}

export default TagsHub

export const Head = ({ data }) => (
  <>
      <title>{`${data.prismicTag.data.name.text.toLowerCase()} → the sun project`}</title>
      <meta name="description" content={data.prismicTag.data.seo_description.text} />
      <meta name="keywords" content={data.prismicTag.data.seo_keywords.text} />
      <meta name="author" content="the sun project" />
      
      <meta property="og:title" content={`${data.prismicTag.data.name.text.toLowerCase()} → the sun project`} />
      <meta property="og:site_name" content="the sun project" />
      <meta property="og:description" content={data.prismicTag.data.seo_description.text} />
      <meta property="og:image" content={`https://thesunproject.fr${defaultImage}`} />
      <meta property="og:locale" content={data.prismicTag.lang} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="the sun project" />
      <meta name="twitter:title" content={`${data.prismicTag.data.name.text.toLowerCase()} → the sun project`}  />
      <meta name="twitter:description" content={data.prismicTag.data.seo_description.text} />
      <meta name="twitter:image" content={`https://thesunproject.fr${defaultImage}`} />

      <link rel="icon" type="image/png" href={favicon} />
  </>
)

export const pageQuery = graphql`
  query TagBySlug($uid: String, $lang: String, $url: String) {
    prismicTag(id: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        name {
          text
        }
        seo_keywords {
          text
        }
        seo_description {
          text
        }
      }
      type
    },
    allPrismicCreation(
      filter: {lang: { eq: $lang }, data: {tags: {elemMatch: {tag: {uid: {eq: $url}}}}}}
      sort: {fields: data___date, order: DESC}
    ) {
      nodes {
        lang
        uid
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          title {
            text
          }
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 3000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          page_color
          client {
            text
          }
          date
          tags {
            tag {
              uid
            }
          }
        }
        type
      }
    }
    allPrismicTag(
      filter: {lang: { eq: $lang }}
      sort: {fields: data___position, order: ASC}
    ) {
      nodes {
        lang
        uid
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          name {
            text
          }
          position
        }
      }
    }
  }
`